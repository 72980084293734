import React from "react";
import Hero from "./Hero";
import About from "./About";
import Projects from "./Projects";
import Footer from "./Footer";

const Home = () => {
  return (
    <div id="parent">
      <div className="layout-container">
        <Hero />
        <About />
        {/* <Expertise /> */}
        <Projects />
        {/* <Projects1 />*/}
        {/* <Contact /> */}
        {/* <NumberPopup /> */}
        {/* <div id="tidycal-embed" data-path="chinmay/15-minute-meeting"></div> */}
        <Footer />
      </div>
    </div>
  );
};

export default Home;
