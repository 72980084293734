import React from "react";

const Navbar = () => {
  return (

    <div id="nav-parent">
      <nav class="navbar">
        <h2 class="logo"><span style={{"padding-right": "5px"}}>🚀</span>ReachRocket</h2>

        <input type="checkbox" id="click" />
        <label for="click" class="menu-btn">
          <i class="fa fa-bars" aria-hidden="true" onclick="ulClose()"></i>
        </label>

        <ul class="menu_ul">
          <li class="nav_home"><a href="#home">Home</a></li>
          <li class="nav_about"><a href="#about">About</a></li>
          <li class="nav_work"><a href="#work">Work</a></li>
          <li class="nav_contact"><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
