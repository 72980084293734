import React, { useState, useEffect } from "react";

const FullPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ ]);

  return (
    <section className="fullpage_container" id="project">
       
    </section>
  );
};

export default FullPage;
