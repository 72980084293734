import logo from "./logo.svg";
import "./App.css";
import "./scss/styles.css";
import { Route, Switch, Link, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Navbar from "./components/Navbar/Navbar";
import FullPage from "./components/FullPage";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />}>
          {/* <Home /> */}
        </Route>
        <Route path="/project/:id" element={<FullPage />}></Route>
      </Routes>
    </>
  );
}

export default App;

// chinmay