import React from "react";

const Footer = () => {
  return (
    <footer className="footer_container">
      <p class="logo">ReachRocket</p>

      <div className="more-info">
      ReachRocket backs you up with one stop powerful solution that enables you to
        find creators you can trust & build relationships with, expand your
        brand awareness, increase conversions, and measure results all at one
        place.
        <p className="email">Mail us - astha@getreachrocket.com</p>
      </div>
      <div className="footer-social">
        <a
          href="https://www.linkedin.com/company/"
          className="home_social-icon"
          target="_blank"
        >
          <i class="fab fa-linkedin-in"></i>
        </a>
{/* chinmay */}
        <a
          href="https://twitter.com/"
          className="home_social-icon"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
