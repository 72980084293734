import React from "react";

const Projects = () => {
  return (
    <section className="projects_container" id="work">
      {/* <div className="projects_box1">
        <h3>
          Our Projects
          <div className="btn">Take a Look</div>
        </h3>
      </div> */}
      <div className="projects_box1">
        <h3>Our Clientele</h3>
      </div>

      {/* <div className="project-b2" style={{display: "display"}}> */}
      <div className="project-b2">
        <div className="in-box">
          <p className="client">
            <img
             style={{ width: "70px", height: "70px" }}
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/272249193_102022592391543_5331261797675422517_n__1_-removebg-preview_a0L9F0xK1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654514202510"
              alt=""
            />
            MyStay
          </p>
          <p className="client">
            <img
               style={{ width: "140px", }}
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/logo_IEm7423Qa.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654511946692"
              alt=""
            />
            Tickendy
          </p>
          <p className="client">
            <img
             style={{ width: "140px", height: "30px" }}
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/logo-black-2000px-w-png_190x_ZdrVNhtWQ.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1654511963780"
              alt=""
            />
            Wellversed
          </p>
          <p className="client">
            <img
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/logo-icon_GLXCgRfWy.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654513041285"
              alt=""
            />
            Levitee Labs
          </p>
          <p className="client">
            <img
              style={{ width: "50px", height: "50px" }}
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/Retrospec_Riff_Orange_RGB_1_100x_2x_0WgO_ED_i.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1654511947111"
              alt=""
            />
            Retrospec
          </p>
          <p className="client">
            <img
              style={{ width: "60px", height: "50px" }}
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/download__1_-removebg-preview_2Y34meG51.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654514017923"
              alt=""
            />
            Figma
          </p>
          <p className="client">
            <img
             style={{ width: "140px" }}
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/cropped-cropped-Book-Studio-Landscape-Logo-e1639128881903_MfStV5gbX.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1654511963982"
              alt=""
            />
            BookStudio
          </p>
          <p className="client">
            <img
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/ZestLogo_av2qm__8r.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654511948470"
              alt=""
            />
            ZestMoney
          </p>
          <p className="client">
            <img
              className="logo1"
              src="https://ik.imagekit.io/k3m4pqzpmlr/Logo/download__2_-removebg-preview_7SAXMq2NI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654514017925"
              alt=""
            />
            Comet
          </p>
          {/* <p className="client">and many more...</p> */}
        </div>
      </div>
    </section>
  );
};

export default Projects;
