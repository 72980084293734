import React from "react";
import sicx from "./img2/6.png";

const Hero = () => {
  return (
    <section className="hero-container" id="home">
      {/* <img src={sicx}  className="img2"/> */}

      <div className="hero-box1">
        <h1>
          Superlative Growth With <br></br>{" "}
          <span className="gradient"> Influencer Marketing</span>
        </h1>
        <p>Active database of 5,00,000+ influencers and creators</p>
        
        <div className="hero-btn">
          <a
            // href="https://tidycal.com/chinmay/30-minute-meeting"
            href="https://calendly.com/reachrocket-co/one-on-one-introductory-call-with-team-reach-rocket?month=2024-01"
            target="_blank"
          >
            Get Started{" "}
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
