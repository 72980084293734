import React from "react";

const About = () => {
  return (
    <section className="about_container" id="about">
      <div className="about-box1">
        <div className="x1">
          <h1>Forget Paid Advertising. Grow with Influencer Marketing.</h1>

          <p>
            The creator economy is expected to touch $200B in 2023. The
            turnaround success time for influencer/creator campaigns is much
            quicker than ad campaigns on any social media platform.
          </p>
        </div>
        <div className="x2">{/* blank */}</div>
      </div>

      <div className="about-box2-grid">
        <div className="b1">
          <lord-icon
            src="https://cdn.lordicon.com/iltqorsz.json"
            trigger="loop"
            colors="primary:#000000,secondary:#c70fff"
            delay="1000"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon>

          <h3>Recognized Creator Community</h3>

          <p>
            After working with 1000s of influencers, we have decided upon a
            select few who have proven to generate the highest ROI campaigns;
            where we work on influencer contracting, briefing and operations.
          </p>
        </div>
        <div className="b1">
          <lord-icon
            src="https://cdn.lordicon.com/wxnxiano.json"
            trigger="loop"
            colors="primary:#000000,secondary:#c70fff"
            delay="1000"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon>

          <h3>Extensive Industry Research</h3>

          <p>
            Having worked with companies in various industries ranging from
            Fintech to EdTech, and eCommerce to Healthcare - we have a set of
            KPIs to go about researching the impact we can generate for a brand.
          </p>
        </div>
        <div className="b1">
          <lord-icon
            src="https://cdn.lordicon.com/wloilxuq.json"
            trigger="loop"
            colors="primary:#000000,secondary:#c70fff"
            delay="1000"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon>

          <h3>Formulation of an Idea</h3>

          <p>
            Great campaigns require great ideas; and proposing unique creative
            ideas for influencer-led branded content is something where our
            creative strategy team shines, and consistently smashes engagement
            averages.
          </p>
        </div>
        <div className="b1">
          <lord-icon
            src="https://cdn.lordicon.com/nocovwne.json"
            trigger="loop"
            colors="primary:#000000,secondary:#c70fff"
            delay="1000"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon>

          <h3>Scripting</h3>

          <p>
            It is said - you are successful in advertising when the viewers
            cannot distinguish whether it is an ad or a sequence of the
            presented topic.
            {/* Our approach towards creating a script does not
            deteriorate the experience of a viewer, helping us design
            High-Impact campaigns.  */}
          </p>
        </div>
        <div className="b1">
          <lord-icon
            src="https://cdn.lordicon.com/zpxybbhl.json"
            trigger="loop"
            colors="primary:#000000,secondary:#c70fff"
            delay="1000"
            style={{ width: "120px", height: "120px" }}
          ></lord-icon>

          <h3>Creative Planning & Distribution</h3>

          <p>
            An extensive distribution and creative planning go hand-in-hand to
            create a semi-viral ad piece which tends to create a craving for
            your product/service.
          </p>
        </div>
        <div className="get-started">
          <h3>
            Forget Social Media Ads. Achieve Next Level Performance With
            Influencer Marketing.
          </h3>

          <div className="btn">
            <a
              href="www.google.com"
              target="_blank"
            >
              Get Started{" "}
              <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
